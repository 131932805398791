<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMetafield, {
  Metafield,
} from '/~/components/base/metafield/base-metafield.vue'
import { type BatchOrder, BatchOrderType } from '/~/composables/batch-order'
import PaymentsMakeSection from './section.pure.vue'

type PayingToBatchForm = {
  amount?: string
}

type PayingToBatchProps = {
  batchOrder: BatchOrder
  formFields: Metafield[]
  loading: boolean
  canView?: boolean
  canRemove?: boolean
}

const props = withDefaults(defineProps<PayingToBatchProps>(), {
  canView: false,
  canRemove: false,
})

const emit = defineEmits<{
  (event: 'form-update', payload: PayingToBatchForm): void
  (event: 'view'): void
  (event: 'remove'): void
}>()

const payingToForm = ref<PayingToBatchForm>({
  amount: String(props.batchOrder.initialAmount),
})

const fileTypeText = computed(() =>
  String(props.batchOrder.type) === 'payroll' ? 'Payroll file' : 'Batch file'
)

const paymentsCountMessage = computed(() => {
  const count = props.batchOrder.transactionsStatusCount.reduce(
    (accumulator, currentValue) => accumulator + currentValue.count,
    0
  )

  return `${count} payment${count !== 1 ? 's' : ''}`
})

watch(
  () => props.batchOrder,
  (value) => {
    payingToForm.value.amount = String(value.initialAmount)

    emit('form-update', payingToForm.value)
  },
  { deep: true }
)

watch(
  () => props.loading,
  (loading) => {
    if (!loading) {
      emit('loaded')
    }
  }
)
</script>

<template>
  <payments-make-section label="Paying to" :loading="loading">
    <div class="flex h-12 items-center justify-between border px-3">
      <div class="flex w-7/12 flex-wrap sm:w-9/12">
        <div class="truncate pr-1">
          {{ fileTypeText }}: {{ batchOrder.fileName }}
        </div>
        <div>({{ paymentsCountMessage }})</div>
      </div>

      <div class="flex divide-x">
        <div v-if="canView">
          <base-button look="link" class="mr-4" @click="emit('view')">
            <span class="text-base font-normal underline underline-offset-4">
              View
            </span>
          </base-button>
        </div>
        <div v-if="canRemove">
          <base-button look="link" class="ml-4" @click="emit('remove')">
            <span class="text-base font-normal underline underline-offset-4">
              Remove
            </span>
          </base-button>
        </div>
      </div>
    </div>

    <base-metafield
      v-model="payingToForm"
      :submitting="false"
      :schema="{
        fields: formFields,
        types: {},
      }"
      :backend-errors="{}"
    />
  </payments-make-section>
</template>
