<script setup lang="ts">
import { Dayjs } from 'dayjs/esm'
import { computed, ref, watch } from 'vue'
import ui from '/~/core/ui'
import formatDate, { createDate } from '/~/utils/format/date'
import BaseDatepicker from '/~/components/base/datepicker/base-datepicker.vue'
import Datepicker from '/~/components/base/datepicker/datepicker/datepicker.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRadio from '/~/components/base/radio/base-radio.vue'
import { useLocalization } from '/~/composables/localization'
import DrawerModal from '/~/templates/bill-payments/components/drawer-modal.vue'
import PaymentsMakeSection from './section.pure.vue'
import HowToPayHintPure from '../components/how-to-pay/hint.pure.vue'

export type WhenToPayScheduled = {
  enabled?: boolean
  from?: Dayjs
  to?: Dayjs
  hintMessage?: string
}

const props = withDefaults(defineProps<WhenToPayScheduled>(), {
  enabled: false,
})

const emit = defineEmits<{
  (event: 'select', value?: Dayjs): void
}>()

const { getDatePickerFormat } = useLocalization()

const isToggled = ref(false)
const isShowDatepicker = ref(false)
const value = ref(props.from)

const formattedValue = computed(() =>
  formatDate('daymonthyearnumeric', value.value)
)

function onInput(newDate: string) {
  value.value = createDate(newDate, getDatePickerFormat('daymonthyearnumeric'))
  emit('select', value.value)
}

function onDateToPaySelect(date: any) {
  isShowDatepicker.value = false
  value.value = date
  emit('select', value.value)
}

watch(isToggled, (newValue) => {
  emit('select', newValue ? value.value : undefined)
})

watch(
  () => props.enabled,
  (newValue) => {
    if (!newValue) {
      isToggled.value = false
    }
  }
)
</script>

<template>
  <payments-make-section label="Select when to pay">
    <div class="mb-6 border">
      <base-radio
        v-model="isToggled"
        class="ml-0 rounded-none border-b px-3 py-5"
        :checked="!isToggled"
        :value="false"
      >
        Pay now
      </base-radio>
      <base-radio
        v-model="isToggled"
        class="ml-0 rounded-none px-3 py-5"
        :checked="isToggled"
        :value="true"
        :disabled="!enabled"
      >
        Schedule payment
      </base-radio>
    </div>

    <div v-if="isToggled" class="mt-5">
      <template v-if="ui.mobile">
        <span>Date to pay</span>
        <div
          class="mt-[5px]"
          :selectable="false"
          @click="isShowDatepicker = true"
        >
          <div
            class="flex cursor-pointer justify-between rounded border bg-white px-3 py-2.5 outline outline-2 outline-transparent transition-all duration-300 hover:outline-primary"
          >
            <div class="flex space-x-2.5">
              <base-icon
                svg="v2/custom/calendar"
                class="w-6 text-neutral-600"
              />
              <span>
                {{
                  formattedValue || getDatePickerFormat('daymonthyearnumeric')
                }}
              </span>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <base-datepicker
          :value="formattedValue"
          entry-class="rounded-none h-12"
          label="Date to pay"
          icon="v2/custom/calendar"
          :placeholder="getDatePickerFormat('daymonthyearnumeric')"
          icon-class="text-inherit"
          format="daymonthyearnumeric"
          position-relative
          :disabled-dates="{
            to: from,
            from: to,
          }"
          :min-date-mobile="from && from.toISOString().split('T')[0]"
          :max-date-mobile="to && to.toISOString().split('T')[0]"
          @input="onInput"
        />
      </template>
      <drawer-modal
        :visible="isShowDatepicker"
        @hide="isShowDatepicker = false"
      >
        <datepicker
          v-analytics:select.custom="{
            pageGroup: 'Make a payment',
            page: 'Checkout',
            component1: 'Select date mobile drawer',
            label: 'Set payment date',
          }"
          :active-period="{
            to: from,
            from: to,
          }"
          :value="value"
          format="daymonthyearnumeric"
          display-mode="calendar"
          @select="onDateToPaySelect"
        />
      </drawer-modal>
    </div>
    <how-to-pay-hint-pure v-if="!enabled" :text="hintMessage" />
  </payments-make-section>
</template>
