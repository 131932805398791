<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { type BankAccount } from '../how-to-pay/db-option.vue'
interface Props {
  method: BankAccount
  isSelected?: boolean
  noPadding?: boolean
  icon?: string
  isVerified?: boolean
}
withDefaults(defineProps<Props>(), {
  isSelected: false,
  noPadding: false,
  icon: '',
  isVerified: true,
})
const { translate } = useLocalization()
</script>

<template>
  <div
    class="flex cursor-pointer items-center justify-start py-3"
    :class="{
      'bg-primary-lightest': isSelected,
      'hover:bg-slate-50': !isSelected,
      'px-3': !noPadding,
    }"
    @click="(e) => $emit('click-option', e)"
  >
    <base-icon :svg="icon" :size="24" class="mr-2 text-primary" />

    <strong>
      <span class="mr-1.5">{{ method.accountName }}</span>
      {{
        translate('bankAccount.detailsWithoutLabel', {
          acc: method.accountNumber,
          bsb: method.bsb,
        })
      }}
    </strong>
    <div
      v-if="!isVerified"
      class="ml-5 max-h-max rounded border border-error bg-red-50 px-2 py-1 text-xs text-fg-error grayscale-0"
    >
      VERIFY ACCOUNT
    </div>
  </div>
</template>
