<script setup lang="ts">
import { computed } from 'vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

interface Props {
  text?: string
  icon?: string
  type?: 'info' | 'warning'
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  type: 'info',
})

const presets = computed(() => {
  const infoPreset = {
    icon: 'heroicons/mini/information-circle',
    overallClass: 'text-fg-info border-info',
    backgroundClass: 'bg-info',
  }

  const warningPreset = {
    icon: 'heroicons/mini/exclamation-triangle',
    overallClass: 'text-fg-warning border-warning',
    backgroundClass: 'bg-warning',
  }

  switch (props.type) {
    case 'info':
      return infoPreset
    case 'warning':
      return warningPreset
    default:
      return infoPreset
  }
})
</script>

<template>
  <div
    v-if="text"
    class="relative flex items-center justify-start rounded-sm border px-3 py-2 text-sm leading-none"
    :class="presets.overallClass"
  >
    <div class="absolute inset-0 z-0" :class="presets.backgroundClass" />
    <div class="z-1 mr-2.5 w-5">
      <base-icon :svg="icon || presets.icon" :size="20" class="-my-px" />
    </div>
    <span class="z-1">
      {{ text }}
    </span>
  </div>
</template>
