<script setup lang="ts">
import { Dayjs } from 'dayjs/esm'
import debounce from 'lodash-es/debounce'
import { ValidationObserver } from 'vee-validate'
import { ref, computed, reactive, nextTick } from 'vue'
import {
  PaymentMethodBankAccount,
  PaymentMethodCreditCard,
  ProgramFees,
} from '/~/types/api'
import BaseAction from '/~/components/base/action/base-action.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import { Metafield } from '/~/components/base/metafield/base-metafield.vue'
import { useForm } from '/~/composables/base/use-form'
import { type BatchOrder } from '/~/composables/batch-order'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import HowToPayHintPure from './components/how-to-pay/hint.pure.vue'
import HowToPayPure, {
  type PayByProps,
  type TypeSelectEventPayloads,
  type ValueChangeEventPayloads,
} from './components/how-to-pay.pure.vue'
import PayingToBatchPure from './components/paying-to-batch.pure.vue'
import PayingToPure, {
  PayingToPayeeExtended,
  PayingToForm,
  PayingToStatementExtended,
  type PayingToPayee,
  type PayingToProps,
} from './components/paying-to.pure.vue'
import SectionPure from './components/section.pure.vue'
import WhenToPayPure, {
  WhenToPayScheduled,
} from './components/when-to-pay.pure.vue'

export type PaymentsMakePureProps = {
  payee?: PayingToPayee
  payeeForm: PayingToForm
  payeeFormFields: Metafield[]
  minAmountValue?: number
  maxAmountValue?: number
  batchOrder?: BatchOrder
  providerTitle: string
  pointsSplitSettings: {
    enabled: boolean
    pointsPlusCardEnabled: boolean
    pointsPlusBankAccountEnabled: boolean
  }
  pointsBalance: number
  payees: PayingToProps['payees']
  burnPointsRates: PayByProps['burnPointsRates']
  paymentOptions: PayByProps['paymentOptions']
  scheduled: Omit<WhenToPayScheduled, 'whenToPayHintMessage'>
  getPayByAccount: PayByProps['getPayByAccount']
  getPayId: PayByProps['getPayId']
  getCreditCards: PayByProps['getCreditCards']
  getBankAccounts: PayByProps['getBankAccounts']
  getPointsBalance: PayByProps['getPointsBalance']
  getProcessingFee?: (id: string) => Promise<string>
  onProcessingTimes?: () => void | Promise<void>
  onTermsAndConditions?: () => void
  onAddNewPaymentMethod?: () => void
  onViewBatch?: () => void
  onRemoveBatch?: () => void
  loading?: boolean
  processing?: boolean
  hasPointsMethod: boolean
  calculatePoints?: (sum: string) => void
  pay?: (payload: any) => Promise<void>
  flowType: FlowType
  hints?: PayByProps['hints']
  isAddingNewPayeeEnabled?: boolean
  paymentStatus: {
    ready: boolean
    loading: boolean
  }
  processingTimes: PayByProps['processingTimes']
  programFees?: ProgramFees
  taxation: {
    label: string
  }
  whenToPayHintMessage?: string
  isCheckingIdentityVerification?: boolean
}

const props = withDefaults(defineProps<PaymentsMakePureProps>(), {
  providerTitle: '',
  loading: false,
  paymentOptions: () => [],
  onTermsAndConditions: () => {},
  calculatePoints: () => {},
  pay: () => Promise.resolve(),
  hints: () => [],
  isAddingNewPayeeEnabled: false,
  isCheckingIdentityVerification: false,
})

const emit = defineEmits<{
  (event: 'manage-payees'): void
  (event: 'payee-select', payee?: PayingToPayee): void
  (event: 'payee-form-update', updatedForm: PayingToForm): void
  (
    event: 'points-to-use-change',
    pointsToUse: {
      pointsToUse: number
      calculatedAmount: number
    }
  ): void
  (
    event: 'payment-method-select',
    method?: PaymentMethodCreditCard | PaymentMethodBankAccount
  ): void
  (event: 'how-to-pay-select'): void
  (event: 'processing-times'): void
  (event: 'submit', summary?: any): void
  (event: 'cancel'): void
}>()

const { validationObserverRef } = useForm()
const { translate } = useLocalization()

const payByType = ref<TypeSelectEventPayloads['type']>()
const tocAccepted = ref(false)
const payrollConfirmed = ref(false)
const selectedPaymentMethod = ref<
  PaymentMethodCreditCard | PaymentMethodBankAccount | null
>(null)
const title = computed(() =>
  props.flowType === FlowType.payroll ? 'Pay your payroll' : 'Make a payment'
)
const isPaymentMethodTypeSelected = computed(() => Boolean(payByType.value))
const isAmountCovered = computed(
  () =>
    selectedPaymentMethod.value ||
    (isPointsSelected.value &&
      calculatedAmount.value >= subTotalWithProgramFees.value)
)
const isAmountValid = computed(() => {
  const amount = parseFloat(props.payeeForm.amount ?? '0')

  return amount >= props.minAmountValue && amount <= props.maxAmountValue
})
const canScheduled = computed(
  () => props.payee && isAmountValid.value && isAmountCovered.value
)

const canContinue = computed(
  () =>
    (props.batchOrder?.isPayroll ? payrollConfirmed.value : true) &&
    !props.processing &&
    isPayeeSelected.value &&
    tocAccepted.value &&
    isPaymentMethodTypeSelected.value &&
    isAmountCovered.value
)
const isBatchOrder = computed(() =>
  [FlowType.batch, FlowType.payroll].includes(props.flowType)
)
const isPayByBankAccount = computed(
  () => payByType.value === PaymentMethodType.bankAccount
)
const isPayByPoints = computed(
  () => payByType.value === PaymentMethodType.points
)

const isShowHint = computed(
  () =>
    isPayByBankAccount.value ||
    (isPayByPoints.value &&
      selectedPaymentMethod.value?.type === PaymentMethodType.bankAccount)
)

const subTotalWithProgramFees = computed(
  () => props.programFees?.subtotal || parseFloat(props.payeeForm.amount ?? '0')
)
const processingFeeOptions = reactive({
  loading: false,
  value: 0,
})

function onPayeeSelect(selectedPayee?: PayingToPayee) {
  validationObserverRef.value?.reset()
  emit('payee-select', selectedPayee)
  resetHowToPay()
  resetWhenToPay()
  resetAgreement()

  if (selectedPayee?.type === 'statement') {
    nextTick(() => validationObserverRef.value?.validate())
  }
}

function onPayeeFormUpdate(payload: PayingToForm) {
  resetHowToPay()
  resetWhenToPay()
  resetAgreement()

  emit('payee-form-update', payload)
}

function onPayingToLoaded() {
  nextTick(() => {
    if (Object.values(props.payeeForm).some((value) => value !== undefined)) {
      validationObserverRef.value?.validate()
    }
  })
}

function onHowToPayTypeSelect(payloads: TypeSelectEventPayloads) {
  payByType.value = payloads.type
  resetWhenToPay()
  resetHowToPayMethod()
  emit('how-to-pay-select')
}

function resetHowToPay() {
  resetHowToPayType()
  resetHowToPayMethod()
}

function resetHowToPayType() {
  payByType.value = null
}

function resetHowToPayMethod() {
  selectedPaymentMethod.value = null
  isPointsSelected.value = false
  calculatedAmount.value = 0
}

function resetAgreement() {
  tocAccepted.value = false
}

function resetWhenToPay() {
  dateToPay.value = undefined
}

const calculatedAmount = ref(0)
const pointsToUse = ref(0)

const onHowToPayValueChangeDebounced = debounce(onHowToPayValueChange, 350)

async function onHowToPayValueChange(payloads: ValueChangeEventPayloads) {
  isPointsSelected.value = payloads?.type === PaymentMethodType.points

  if (isPointsSelected.value && payloads && 'pointsToUse' in payloads) {
    calculatedAmount.value = payloads.calculatedAmount
    pointsToUse.value = payloads.pointsToUse
  } else {
    calculatedAmount.value = 0
    pointsToUse.value = 0
  }

  emit('points-to-use-change', {
    pointsToUse,
    calculatedAmount,
  })

  if (payloads && 'id' in payloads) {
    selectedPaymentMethod.value = payloads.currentMethod ?? null

    emit('payment-method-select', selectedPaymentMethod.value)

    // request processing fee
    if (props.getProcessingFee && isAmountValid.value) {
      processingFeeOptions.loading = true

      try {
        const processingFees = await props.getProcessingFee(payloads.id)

        processingFeeOptions.value = parseFloat(processingFees)
      } catch (error) {
        console.error('payment-v1_5', error)
      } finally {
        processingFeeOptions.loading = false
      }
    }
  } else {
    selectedPaymentMethod.value = null

    emit('payment-method-select', selectedPaymentMethod.value)
  }
}

const isPointsSelected = ref(false)

const isPayeeSelected = computed(() =>
  Boolean(props.payee?.id || (isBatchOrder.value && !props.loading))
)

const dateToPay = ref<Dayjs | undefined>()

function onUpdateScheduledDate(newDate?: Dayjs) {
  dateToPay.value = newDate
}

function onSubmitForm() {
  const formData = Object.fromEntries(
    new FormData(validationObserverRef.value?.$el as HTMLFormElement)
  )

  const summary = {
    scheduledDate: dateToPay.value,
    payingTo: {
      statement: undefined as PayingToStatementExtended | undefined,
      payee: undefined as PayingToPayeeExtended | undefined,
      amount: props.payeeForm.amount,
      description: formData.description,
      reference: formData.reference,
    },
    payingFrom: {
      method: selectedPaymentMethod.value,
      isPointsSelected: isPayByPoints.value,
      pointsToUse: pointsToUse.value,
      calculatedAmount: calculatedAmount.value,
    },
    payByType: payByType.value,
  }

  if (props.payee?.type === 'statement' && 'raw' in props.payee) {
    summary.payingTo.statement = {
      ...props.payee,
      subtotal: props.payeeForm.amount
        ? parseFloat(props.payeeForm.amount)
        : undefined,
    }
  } else {
    summary.payingTo.payee = props.payee
  }

  emit('submit', summary)
}

const howToPayPureRef = ref<InstanceType<typeof HowToPayPure> | null>(null)

defineExpose({
  howToPayPureRef,
})
</script>

<template>
  <div class="mx-auto h-full w-full max-w-3xl">
    <h1
      class="hidden text-3xl font-bold text-eonx-neutral-800 md:mb-7 md:block"
    >
      {{ title }}
    </h1>
    <validation-observer
      v-slot="{ handleSubmit, valid }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="relative flex grow flex-col"
        @submit.prevent="handleSubmit(onSubmitForm)"
      >
        <div class="divide-y rounded bg-white md:border">
          <paying-to-pure
            v-if="!isBatchOrder"
            :payee="payee"
            :form="payeeForm"
            :form-fields="payeeFormFields"
            :payees="payees"
            :can-manage-payees="isAddingNewPayeeEnabled"
            :provider-title="providerTitle"
            :loading="loading"
            :is-checking-identity-verification="isCheckingIdentityVerification"
            @payee-select="onPayeeSelect"
            @form-update="onPayeeFormUpdate"
            @manage-payees="emit('manage-payees')"
            @loaded="onPayingToLoaded"
          />

          <paying-to-batch-pure
            v-else-if="batchOrder"
            :batch-order="batchOrder"
            :form-fields="payeeFormFields"
            :loading="loading"
            can-view
            can-remove
            @view="onViewBatch"
            @remove="onRemoveBatch"
            @form-update="onPayeeFormUpdate"
            @loaded="onPayingToLoaded"
          />

          <div
            v-if="!loading && paymentStatus.loading"
            class="flex items-center justify-center p-6"
          >
            <base-loader />
          </div>

          <template v-else-if="paymentStatus.ready">
            <how-to-pay-pure
              v-if="isPayeeSelected && isAmountValid"
              ref="howToPayPureRef"
              :processing-fee-options="processingFeeOptions"
              :burn-points-rates="burnPointsRates"
              :points-balance="pointsBalance"
              :flow-type="flowType"
              :paying-to="payee"
              :payment-options="paymentOptions"
              :get-pay-by-account="getPayByAccount"
              :get-pay-id="getPayId"
              :get-credit-cards="getCreditCards"
              :get-bank-accounts="getBankAccounts"
              :has-points-method="hasPointsMethod"
              :get-points-balance="getPointsBalance"
              :on-add-new-payment-method="onAddNewPaymentMethod"
              :is-checking-identity-verification="
                isCheckingIdentityVerification
              "
              :amount="subTotalWithProgramFees"
              :payment-split-allowed="pointsSplitSettings.enabled"
              :hints="hints"
              :is-loading-program-fees="paymentStatus.loading"
              :processing-times="processingTimes"
              :taxation="taxation"
              @processing-times="emit('processing-times')"
              @type-select="onHowToPayTypeSelect"
              @value-change="onHowToPayValueChangeDebounced"
            />
            <when-to-pay-pure
              v-if="canScheduled"
              :enabled="scheduled.enabled && !isPointsSelected"
              :from="scheduled.from"
              :to="scheduled.to"
              :hint-message="whenToPayHintMessage"
              @select="onUpdateScheduledDate"
            />

            <section-pure v-if="isPayeeSelected" class="!py-5">
              <base-checkbox
                v-model="tocAccepted"
                name="toc"
                :disabled="loading"
              >
                <span class="text-eonx-neutral-800">
                  I agree to the transaction processing
                </span>
                <base-action
                  class="text-primary underline"
                  type="link"
                  :click-only="true"
                  @click="onTermsAndConditions"
                >
                  <strong>
                    Terms {{ translate('common.and') }} Conditions
                  </strong>
                </base-action>
              </base-checkbox>

              <base-checkbox
                v-if="batchOrder && batchOrder.isPayroll"
                v-model="payrollConfirmed"
                name="payrollConfirmed"
                :disabled="loading"
              >
                <span class="text-eonx-neutral-800">
                  I confirm that all payments within the uploaded file are valid
                  payroll payments
                </span>
              </base-checkbox>

              <how-to-pay-hint-pure
                v-if="isShowHint"
                :text="`Payments made via ${translate(
                  'directDebit.text'
                )} may take up to 3 business days`"
                type="warning"
                icon="heroicons/mini/clock"
              />
            </section-pure>

            <section-pure v-if="isPayeeSelected">
              <div class="flex items-center justify-end gap-x-3">
                <base-button
                  look="outlined-color"
                  :disabled="processing || isCheckingIdentityVerification"
                  @click="emit('cancel')"
                >
                  Cancel
                </base-button>
                <base-button
                  type="submit"
                  :loading="processing || isCheckingIdentityVerification"
                  :disabled="
                    !canContinue || !valid || isCheckingIdentityVerification
                  "
                >
                  Continue
                </base-button>
              </div>
            </section-pure>
          </template>
        </div>
      </form>
    </validation-observer>
  </div>
</template>
