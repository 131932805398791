<script setup lang="ts">
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { type BankAccount } from '../how-to-pay/db-option.vue'
interface Props {
  method: BankAccount
  isSelected?: boolean
  noPadding?: boolean
  icon?: string
  isVerified?: boolean
  isOptionSelected?: boolean
}
withDefaults(defineProps<Props>(), {
  isSelected: false,
  noPadding: false,
  icon: '',
  isVerified: true,
  isOptionSelected: false,
})
const { translate } = useLocalization()
</script>

<template>
  <div
    class="flex"
    :class="{
      'bg-primary-lightest': isSelected,
      'hover:bg-slate-50': !isSelected,
      'w-11/12': isOptionSelected,
    }"
    @click="(e) => $emit('click-option', e)"
  >
    <div
      class="flex w-4/6 cursor-pointer flex-col justify-start py-3"
      :class="{
        'px-3': !noPadding,
      }"
    >
      <div class="flex">
        <base-icon :svg="icon" :size="24" class="mr-2 text-primary" />
        <strong class="w-9/12 truncate">
          <span class="mr-1.5">{{ method.accountName }}</span>
          {{
            translate('bankAccount.detailsWithoutLabel', {
              acc: method.accountNumber,
              bsb: method.bsb,
            })
          }}
        </strong>
      </div>
    </div>
    <div
      v-if="!isVerified"
      class="ml-auto mr-2 max-h-max max-w-[90px] self-center rounded border border-error bg-red-50 px-2 py-1 text-center text-xs text-fg-error grayscale-0"
      :class="{
        'ml-2': isOptionSelected,
      }"
    >
      VERIFY ACCOUNT
    </div>
  </div>
</template>
