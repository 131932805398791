<script setup lang="ts">
import { watch } from 'vue'
import { Payee } from '/~/types/api'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseMetafield, {
  Metafield,
} from '/~/components/base/metafield/base-metafield.vue'
import BaseSelect from '/~/components/base/select/base-select.vue'
import { Statement } from '/~/composables/statements'
import PayeeOptionPure from './paying-to/payee-option.pure.vue'
import SectionPure from './section.pure.vue'

export type PayingToForm = {
  amount?: string
  description?: string
  reference?: string
}

export type PayingToPayeeExtended = Pick<Payee, 'type'> & {
  value?: string
  text: string
  id?: string
}

export type PayingToStatementExtended = Pick<Statement, 'raw'> & {
  value?: string
  text: string
  type: 'statement'
  name: string
  providerTitle: string
  number: string
  subtotal?: number
  id?: string
}

export type PayingToPayee = PayingToPayeeExtended | PayingToStatementExtended

export type PayingToProps = {
  payee?: PayingToPayee
  payees: PayingToPayee[]
  form: PayingToForm
  formFields: Metafield[]
  providerTitle: string
  loading: boolean
  canManagePayees: boolean
  isCheckingIdentityVerification: boolean
}

const props = defineProps<PayingToProps>()

const emit = defineEmits<{
  (event: 'payee-select', payload?: PayingToPayee): void
  (event: 'form-update', payload: PayingToForm): void
  (event: 'manage-payees'): void
  (event: 'loaded'): void
}>()

function onSelect(value: string) {
  emit(
    'payee-select',
    props.payees.find((item) => item.value === value)
  )
}

function onInput(payload: PayingToForm) {
  emit('form-update', payload)
}

watch(
  () => props.loading,
  (loading) => {
    if (!loading) {
      emit('loaded')
    }
  }
)
</script>

<template>
  <section-pure label="Paying to" :loading="loading">
    <template v-if="canManagePayees" #subHead>
      <base-button
        size="sm"
        :loading="isCheckingIdentityVerification"
        :disabled="isCheckingIdentityVerification"
        @click="emit('manage-payees')"
      >
        +Add payees
      </base-button>
    </template>

    <base-select
      :value="payee?.value"
      :options="payees"
      class="!pt-0"
      entry-class="rounded-sm h-12 text-eonx-neutral-800 !pl-3"
      select-list-class="divide-y !box-border !py-0 !rounded-sm"
      custom-select
      fullwidth-popup
      no-label
      :select-first="false"
      :popup-offset="{ top: 8 }"
      :placeholder="ui.mobile ? 'Type to search' : 'Select Payee'"
      :search="{
        enabled: true,
        allowSpace: true,
        fields: ['name', 'providerTitle'],
        maxlength: 32,
      }"
      empty-text="no matching Payees"
      @input="onSelect"
    >
      <template #selected="{ option }">
        <payee-option-pure
          :option="option"
          :provider-title="providerTitle"
          no-padding
          is-option-selected
        />
      </template>
      <template #item="{ option, selected }">
        <payee-option-pure
          :option="option"
          :provider-title="providerTitle"
          :show-payee-type="true"
          :is-selected="selected"
          class="p-3"
        />
      </template>
    </base-select>

    <!-- NOTE: key id fixes the bug when we change amount from one value to another during payee select -->
    <!-- example: select banka acc -> enter amount -> select statement -->
    <base-metafield
      v-if="payee?.id"
      :key="payee.id"
      :value="form"
      :schema="{
        fields: formFields,
        types: {},
      }"
      @input="onInput"
    />
  </section-pure>
</template>
