<script setup lang="ts">
import AppLogo from '/~/components/app/app-logo.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'

interface Props {
  option: any
  providerTitle: string
  isSponsored?: boolean
  isBPay?: boolean
  name?: string
  number?: string
  icon?: string
  isPayee?: boolean
  isStatement?: boolean
}

withDefaults(defineProps<Props>(), {})

const { translate, formatBsb } = useLocalization()
</script>

<template>
  <div class="w-8/12">
    <div v-if="isSponsored" class="flex items-center">
      <app-logo :max-width="24" :min-height="true" class="mr-2 shrink-0" />
      <div class="truncate">
        <span class="whitespace-nowrap font-bold text-eonx-neutral-800">
          {{ providerTitle }}
        </span>
        <span class="truncate text-eonx-neutral-600">
          <span class="mx-1 font-bold text-eonx-neutral-800">-</span>
          {{ name }}
          <template v-if="number">
            , Account No.
            {{ number }}
          </template>
        </span>
      </div>
    </div>

    <div v-else-if="isBPay" class="flex items-center">
      <base-icon :svg="icon" :size="24" class="mr-2 shrink-0 text-brand-bpay" />
      <div class="truncate">
        <span class="whitespace-nowrap font-bold text-eonx-neutral-800">
          {{ name }}
        </span>
        <span class="truncate text-eonx-neutral-600">
          <span class="mx-1 font-bold text-eonx-neutral-800">-</span>
          Biller Code: {{ option.billerCode }}, Ref: {{ option.reference }}
        </span>
      </div>
    </div>

    <div v-else-if="isPayee" class="flex items-center">
      <base-icon :svg="icon" :size="24" class="mr-2 shrink-0 text-teal-500" />
      <div class="truncate">
        <span class="w-full whitespace-nowrap font-bold text-eonx-neutral-800">
          {{ name }}
        </span>
        <span class="truncate text-eonx-neutral-600">
          <span class="mx-1 font-bold text-eonx-neutral-800">-</span>
          {{
            translate('bankAccount.details', {
              acc: number,
              bsb: formatBsb(option.bsb),
            })
          }}
        </span>
      </div>
    </div>

    <div v-else-if="isStatement" class="flex items-center">
      <app-logo :max-width="24" :min-height="true" class="mr-2 shrink-0" />
      <div class="truncate">
        <span class="whitespace-nowrap font-bold text-eonx-neutral-800">
          {{ providerTitle }}
        </span>
        <span class="truncate text-eonx-neutral-600">
          <span class="mx-1 font-bold text-eonx-neutral-800">-</span>
          {{ name }}
          <template v-if="number">
            , Account No.
            {{ number }}
          </template>
        </span>
      </div>
    </div>
  </div>
</template>
